export default class Accordion {
	constructor(element) {
		this.element = element;
		this.type = element.getAttribute("data-accordion-type") === "multiple" ? "multiple" : "single";
		this.accordionItems = this.element.querySelectorAll("[js-accordion-item-toggle]");
		this.init();
	}

	init() {
		this.accordionItems.forEach((item) => {
			item.addEventListener("click", () => this.toggleAccordion(item));
		});
	}

	toggleAccordion(btn) {
		const isOpen = btn.getAttribute("aria-expanded") === "true";

		if (isOpen) {
			this.closeAccordion(btn);
		} else {
			this.openAccordion(btn);
		}
	}

	openAccordion(btn) {
		this.type === "single" && this.closeAll();
		btn.setAttribute("aria-expanded", true);
	}

	closeAccordion(btn) {
		btn.setAttribute("aria-expanded", false);
	}

	closeAll() {
		this.accordionItems.forEach((item) => {
			item.setAttribute("aria-expanded", false);
		});
	}
}
