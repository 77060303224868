// import Swiper bundle with all modules installed
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Swiper from 'swiper/bundle';
import Accordion from './Accordion';


let scrollpos = window.scrollY;
const header = document.querySelector("header");
const headerHeight = header.offsetHeight;
const headerElement = document.getElementById("header");
const htmlEle = document.querySelector("html");

$(document).ready(function () {
    const ctaClose = document.querySelector(".demonstration-cta--close-icon")
    if (ctaClose) {
        ctaClose.addEventListener("click", function () {
            document.querySelector(".demonstration-cta").style.display = "none"
        });
    }


    // Slider
    const slider = document.querySelector('.resources-slider-section');

    slider && slider && new Swiper(".mySwiper", {
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        spaceBetween: 10,
        slidesPerView: 1,
        breakpoints: {
            640: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 40,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 40,
            },
        },
    });


    // =========== Search Expansion ============== //

    const nav = document.querySelector(".nav-top"),
        searchIcon = document.querySelectorAll(".search-icon");

    searchIcon.forEach((item) => {
        item.addEventListener("click", () => {
            nav.classList.toggle("openSearch");
            if (nav.classList.contains("openSearch")) {
                return item.classList.add("expanded");
            }
            item.classList.remove("expanded");
        });
    });


    // =========== Search Expansion ============== //

    // const searchbtn = document.getElementById("searchBar");
    // 	const headerElement = document.getElementById("header");
    // 	const element = document.getElementById("expandableSearchBar");
    // 	// Add an event listener for a click to the button
    // 	searchbtn.addEventListener("click", function () {
    // 		element.classList.add("active");
    // 		headerElement.classList.add("searchBarActive");
    // 	});

    // =========== Search Expansion end ============== //


    /* Accordion js start*/

    const membersCatergoriesAccordion = document.querySelector(".faq__accordion");
    membersCatergoriesAccordion && new Accordion(membersCatergoriesAccordion);

    /* Accordion js end*/


    /* Common Sharing function */
    jQuery(document).on("click", ".social-share-mimotec", function (event) {
        var pageTitle = document.title; //HTML page title
        var pageUrl = location.href; //Location of this page
        var shareName = $(this).data("sharetype");
        switch (shareName) {
            case "facebook":
                OpenShareUrl(
                    "https://www.facebook.com/sharer/sharer.php?m2w&u=" +
                    encodeURIComponent(pageUrl) +
                    "&amp;title=" +
                    encodeURIComponent(pageTitle)
                );
                break;
            case "twitter":
                OpenShareUrl(
                    "http://twitter.com/intent/tweet?url=" +
                    encodeURIComponent(pageUrl) +
                    "&amp;text=" +
                    encodeURIComponent(pageTitle)
                );
                break;
            case "linkedin":
                OpenShareUrl(
                    "https://www.linkedin.com/shareArticle?mini=true&url=" +
                    pageUrl +
                    "&title=" +
                    pageTitle +
                    "&summary=" +
                    pageTitle +
                    "&source="
                );
                break;
            case "email":
                OpenShareUrl("mailto:?subject=" + pageTitle + "&body=Found this useful link for you : " + pageUrl);
                break;
            case "copy":
                var $temp = jQuery("<input>");
                jQuery("body").append($temp);
                $temp.val(pageUrl).select();
                document.execCommand("copy");
                $temp.remove();
                break;
        }
    });
    /* End of Common sharing function*/

    /* Open Share URL*/
    function OpenShareUrl(openLink) {
        var winWidth = 650;
        var winHeight = 450;
        var winLeft = (jQuery(window).width() - winWidth) / 2,
            winTop = (jQuery(window).height() - winHeight) / 2,
            winOptions = "width=" + winWidth + ",height=" + winHeight + ",top=" + winTop + ",left=" + winLeft;
        window.open(openLink, "Share This Link", winOptions);
        return false;
    }

    /* End of Open Share URL*/
    // Sticky Header
    // $(window).scroll(function () {
    // 	var sticky = $("header"),
    // 		scroll = $(window).scrollTop(),
    // 		sLogo = $(".s_logo");

    // 	var scrollbtn = $(".scrollTop");
    // 	if (scroll >= 400) scrollbtn.addClass("show");
    // 	else scrollbtn.removeClass("show");

    // 	if (scroll >= 100) sticky.addClass("fixHeader");
    // 	else sticky.removeClass("fixHeader");

    // 	if (scroll >= 400) sLogo.addClass("show");
    // 	else sLogo.removeClass("show");
    // });

    /*Scrol to top*/
    // $(".scrollTop").on("click", function () {
    // 	$("html, body").animate({ scrollTop: 0 }, 600);
    // });
});

// Animation Starts

// toggle menu
var animation = "rubberBand";

$(".menu_btn").on("click", function () {
    $(this).toggleClass("icon--active");
    $(".mainContainer").toggleClass("filter_overlay");
    $(".menu_container").toggleClass("is-open");
});
// Animation Ends

gsap.registerPlugin(ScrollTrigger);

ScrollTrigger.create({
    trigger: ".hero_section",
    start: "top top",
    end: "bottom bottom",
    pin: ".hero_background_video",
});

//===========//

const addClassOnScroll = () => {
    header.classList.add("sticky");
};

const removeClassOnScroll = () => {
    header.classList.remove("sticky");
};

window.addEventListener("scroll", function () {
    scrollpos = window.scrollY;

    if (scrollpos >= headerHeight) {
        addClassOnScroll();
    } else {
        removeClassOnScroll();
    }
});

function windowSize() {
    var size = window.innerWidth;
    if (size >= 992) {
        document.body.classList.remove("nav-is-toggled");
        htmlEle.classList.remove("overflow-hidden");
        hamburger.classList.remove("active");
    }
}

window.onload = function () {
    windowSize();
};

const navExpand = [].slice.call(document.querySelectorAll(".nav-item--toggle"));

navExpand.forEach((item) => {
    item.addEventListener("click", () => {
        item.parentElement.classList.toggle("active");
    });
});

const hamburger = document.getElementById("navToggleIcon");
hamburger.addEventListener("click", function () {
    hamburger.classList.toggle("active");
    document.body.classList.toggle("nav-is-toggled");
    htmlEle.classList.toggle("overflow-hidden");
});


// ==================================================

//






